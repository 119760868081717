import { addScrollListener } from './utils.js';

import $ from 'jquery';

import images from '../img/index.js';

var $spinner = $('.project-vpo-canvas .spinner');

var frames = Object.keys(images.vpo_frames);
var framesLoaded = 0;
frames.forEach(function(key) {
    var index = parseInt(key);
    var img = new Image();
    img.onload = function() {
        framesLoaded++;
        if (framesLoaded === frames.length) {
            $spinner.addClass('hidden');
            console.log('all frames loaded');
        }
        frames[index] = img;
    }
    img.src = images.vpo_frames[key];
});
$spinner.removeClass('hidden');

var $canvas = $('#vpo-canvas');
var ctx = $canvas[0].getContext('2d');

var currentFrame = 0;

// some extra space before the scroll height is taken into account
const SCROLL_PADDING = 0; //$canvas.height() / 4;
function scrollHandler() {
    var scroll = ($(window).scrollTop() + $(window).height() - SCROLL_PADDING - $canvas.offset().top) / ($canvas.height() + $(window).height() - 2*SCROLL_PADDING);

    // limit to range [0, 1]
    scroll = Math.min(1, Math.max(0, scroll));

    // map scroll to frame index, update frame index
    currentFrame = Math.floor(scroll * frames.length);
}
// add to scroll listeners (event is debounced)
addScrollListener(scrollHandler, true);

// update loop that redraws the canvas
function updateCanvas() {
    // draw currentFrame if the image was loaded successfully
    if (typeof frames[currentFrame] === 'object') {
        ctx.drawImage(frames[currentFrame], 0, 0);
    }
    window.requestAnimationFrame(updateCanvas);
}
window.requestAnimationFrame(updateCanvas);

